import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IParticipantSectionsContext } from './ParticipantSectionsContext';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { isUserJoinedAlready } from '../User/helpers/userTypeHandlers';
import { isMockedChallenge } from '../main/getMockedChallenges';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { isForcedPreviewParticipant } from '../../selectors/isForcedPreview';
import { toParticipantSections } from './toParticipantSections';
import { getUrlParams } from '../Location/locationProviderPropsMap';
import {
  getChallengeData,
  requestChallenge,
} from '../storage-contexts/Challenge';
import {
  listSections,
  getSection,
  getStep,
} from '@wix/ambassador-challenges-v1-challenge/http';
import {
  Challenge,
  DescriptionFieldSet,
} from '@wix/ambassador-challenges-v1-challenge/types';
import {
  getSection as getParticipantSection,
  getStep as getParticipantStep,
  listSections as listParticipantSections,
  myProgramSection,
} from '@wix/ambassador-challenges-v1-participant/http';
import {
  ChallengeSection,
  ParticipantSection,
  State as ParticipantState,
  ParticipantStep,
} from '@wix/ambassador-challenges-v1-participant/types';
import { request } from '../../services/request';
import { patchParticipantSectionList } from '@wix/challenges-web-library';
import { getChallengesListWithMocks } from '../storage-contexts/ChallengesList';

export const isRequiredOwnerData = (flowAPI: ControllerFlowAPI) => {
  const { isEditor, isPreview } = flowAPI.environment;

  return (
    isEditor ||
    isPreview ||
    isForcedPreviewParticipant(
      flowAPI?.controllerConfig?.wixCodeApi?.location?.query,
    )
  );
};

export const loadParticipantSections = async (
  flowAPI: ControllerFlowAPI,
  program: Challenge,
): Promise<IParticipantSectionsContext['listParticipantSections']> => {
  const { isEditor } = flowAPI.environment;
  const userProvider = await userProviderPropsMap(flowAPI);
  const { participant } = userProvider;
  const isJoinedParticipant = isUserJoinedAlready(
    participant?.transitions?.[0]?.state,
  );
  const challengeId = program?.id;
  let sections: ChallengeSection[] = [];

  if (isMockedChallenge(challengeId, flowAPI)) {
    return [];
  }

  flowAPI.controllerConfig.setProps({
    isListParticipantSectionsRequestInProgress: true,
  });

  if (
    isJoinedParticipant &&
    userProvider.userType !== ParticipantState.RUNNING &&
    program?.stepsSummary?.sectionsNumber > 0
  ) {
    try {
      sections = (
        await request(
          flowAPI,
          listParticipantSections({
            challengeId,
            participantId: participant?.id,
            descriptionFieldSet: DescriptionFieldSet.STANDARD,
          }),
        )
      )?.data?.sections;
    } catch (error) {
      handleError({ error, context: 'loadSections' });
    }
  }

  if (isRequiredOwnerData(flowAPI) && !sections?.length) {
    const challengeIdForPreview =
      challengeId ||
      (await getChallengesListWithMocks(flowAPI))?.memberChallenges?.[0]
        ?.challenge?.id;

    if (challengeIdForPreview) {
      try {
        const ownerSections = (
          await request(
            flowAPI,
            listSections({
              challengeId: challengeIdForPreview,
              descriptionFieldSet: DescriptionFieldSet.STANDARD,
            }),
          )
        )?.data?.sections;
        sections = toParticipantSections(ownerSections);
      } catch (error) {
        handleError({
          error,
          context: isEditor
            ? 'loadParticipantSections[editor]'
            : 'loadParticipantSections[preview]',
        });
      }
    }
  }

  flowAPI.controllerConfig.setProps({
    isListParticipantSectionsRequestInProgress: false,
  });

  return patchParticipantSectionList(sections);
};

export async function getSectionNavigationInfo(
  flowAPI,
  sections: ChallengeSection[],
): Promise<ChallengeSection | undefined> {
  const urlParams = getUrlParams(flowAPI);
  if (urlParams.navigationType === 'section') {
    const originalSection = sections.find(
      (s) => s.id === urlParams.navigationId,
    );
    if (originalSection) {
      return originalSection;
    }

    const challenge = await requestChallenge(urlParams.slug, flowAPI);
    return (
      await request(
        flowAPI,
        myProgramSection({
          programSectionId: urlParams.navigationId,
          programId: challenge.challenge.id,
        }),
      )
    )?.data?.participantSection;
  }
}

export async function requestParticipantSection(
  sectionId: string,
  sections: ParticipantSection[],
  flowAPI: ControllerFlowAPI,
): Promise<void> {
  // here we assume, that challenge, participant and sections already loaded
  const challengeData = await getChallengeData(flowAPI);
  const challenge = challengeData?.challenge || null;
  const participant = (await userProviderPropsMap(flowAPI))?.participant;
  // const sections = (await participantSectionsPropsMap(flowAPI))
  //   ?.listParticipantSections;
  const isJoinedParticipant = isUserJoinedAlready(
    participant?.transitions?.[0]?.state,
  );
  const isPreviewWithParticipant =
    flowAPI.environment.isPreview && isJoinedParticipant;
  const isOwnerData = isRequiredOwnerData(flowAPI) && !isPreviewWithParticipant;

  if (!challenge || !(isOwnerData || participant) || !sections) {
    console.error("Can't request participant section, not enough data.", {
      challenge,
      participant,
      sections,
      isOwnerData,
    });
    return;
  }

  const sectionToUpdate = sections.find((section) => {
    return section?.id === sectionId;
  });
  let description: any = null;

  if (!sectionToUpdate) {
    console.error("Can't find participant section for update.");
  }

  if (!isOwnerData) {
    try {
      const section = (
        await request(
          flowAPI,
          getParticipantSection({
            challengeId: challenge.id,
            participantId: participant.id,
            sectionId,
            descriptionFieldSet: DescriptionFieldSet.EXTENDED,
          }),
        )
      )?.data?.section;

      description = section?.source?.settings?.description;
    } catch (error) {
      console.error(error);
      handleError({
        error,
        context: 'requestParticipantSection (participant)',
      });
    }
  } else {
    try {
      const section = (
        await request(
          flowAPI,
          getSection({
            challengeId: challenge.id,
            sectionId,
            descriptionFieldSet: DescriptionFieldSet.EXTENDED,
          }),
        )
      )?.data?.section;

      description = section?.settings?.description;
    } catch (error) {
      console.error(error);
      handleError({
        error,
        context: 'requestParticipantSection (challenge)',
      });
    }
  }

  if (sectionToUpdate.source?.settings) {
    sectionToUpdate.source.settings.description = description;
  }

  flowAPI.controllerConfig.setProps({
    listParticipantSections: sections,
  });
}

export async function requestParticipantStep(
  stepId: string,
  steps: ParticipantStep[],
  flowAPI: ControllerFlowAPI,
): Promise<void> {
  // here we assume, that challenge, participant and sections / steps already loaded
  const challengeData = await getChallengeData(flowAPI);
  const challenge = challengeData?.challenge || null;
  const participant = (await userProviderPropsMap(flowAPI))?.participant;
  // const steps = (await participantSectionsPropsMap(flowAPI))?.participantSteps
  //   ?.steps;
  const isJoinedParticipant = isUserJoinedAlready(
    participant?.transitions?.[0]?.state,
  );
  const isPreviewWithParticipant =
    flowAPI.environment.isPreview && isJoinedParticipant;
  const isOwnerData = isRequiredOwnerData(flowAPI) && !isPreviewWithParticipant;

  if (!challenge || !(isOwnerData || participant) || !steps) {
    console.error("Can't request participant step, not enough data.", {
      challenge,
      participant,
      steps,
      isOwnerData,
    });
    return;
  }

  const stepToUpdate = steps.find((step) => {
    return step?.id === stepId;
  });
  let description: any = null;

  if (!stepToUpdate) {
    console.error("Can't find participant step for update.");
    return;
  }

  if (!isOwnerData) {
    try {
      const step = (
        await request(
          flowAPI,
          getParticipantStep({
            challengeId: challenge.id,
            participantId: participant.id,
            stepId,
            descriptionFieldSet: DescriptionFieldSet.EXTENDED,
          }),
        )
      )?.data?.step;

      description = step?.source?.settings?.general?.description;
    } catch (error) {
      console.error(error);
      handleError({ error, context: 'requestParticipantStep (participant)' });
    }
  } else {
    try {
      const step = (
        await request(
          flowAPI,
          getStep({
            challengeId: challenge.id,
            stepId,
            descriptionFieldSet: DescriptionFieldSet.EXTENDED,
          }),
        )
      )?.data?.step;

      description = step?.settings?.general?.description;
    } catch (error) {
      console.error(error);
      handleError({ error, context: 'requestParticipantStep (challenge)' });
    }
  }

  if (stepToUpdate.source?.settings?.general) {
    stepToUpdate.source.settings.general.description = description;
  }

  flowAPI.controllerConfig.setProps({
    participantSteps: { steps },
  });
}
